import { ReactNode } from "react";

/* Cette fonction astucieuse permet de vérifier qu'un tableau contient
 * toutes les éléments d'un type donné en paramètre.
 * Pour cela elle verifie que le tableau est bien un tableau du type donné (première inclusion)
 * puis que le type pourrait hériter du type d'un élément du tableau (deuxième inclusion).
 */
type Invalid<T> = ["Needs to be all of", T]
export const arrayOfAll = <T>() => <U extends T[]>(
  ...array: U & ([T] extends [U[number]] ? unknown : Invalid<T>[])
) : T[] => array;


/**
 * Énumération des roles (niveaux d'autorisation) sur le site
 * @see role.ts:requestMinimumRole
 */
export enum Role {
    RANDOM = 0, // Personne possédant un compte Rezel mais n'étant pas télécommien
    TELECOMMIEN = 1, // Télécommien wsh
	PREZ = 2, // Responsable informatique / Président d'une association
    BDE = 3, // Membre du BDE
	TREZ = 4, // (Vice-)Trésorier(e) du BDE
    ADMIN = 5 // Respos infos
}


export interface FullName {
	firstname: string
	lastname: string
}

/**
 * Utile pour faire des listes déroulantes d'utilisateurs
 * @see UserSearchField.tsx:UserSearchField
 */
export interface UserToDisplay extends FullName {
	id: number
}

/**
 * Un utilisateur avec toutes ses informations
 * Correspond à une ligne de la table Users en BDD
 * 
 * @see db.ts:DB::UsersTable
 */
export interface User extends UserToDisplay {
	preferred_username: string | null
	email?: string
	phone?: string
	role: Role
	promo: number
	sam: boolean
	locked: boolean
	cotisationId: number | null
	echelon: number
	emailParrainage: string | null
}

/**
 * Un BDE (destiné à être affiché dans la page "LE BDE")
 * Correpond à un élément de membre_bde.json
 */
export interface Bde {
	firstname: string
	lastname: string
	bureau: boolean
	img: string
	poles: string[]
}

export interface Rec {
    firstname: string
    lastname: string
    bde: boolean
    img: string
    number: string
}

/**
 * Une entrée dans la blacklist
 * Ensemble des informations qui vont être comparés aux utilisateurs prenant des places
 * Correspond à une ligne de la table Blacklist en BDD
 * 
 * @see db.ts:DB::BlacklistTable
 */
export interface BlacklistEntry {
	id: number
	userId: number
	email: string
	phone: string
	firstname: string
	lastname: string
	reason: string
	date: string
}

/**
 * Une entrée dans la DB Rplace
 * Ensemble des informations qui décrivent un block dans le tableau de pixels
 * Correspond à une ligne de la table Rplace en BDD
 * 
 * @see db.ts:DB::RplaceTable
 */
export interface RplaceEntry {
	id: number
	color: string
}

/**
 * Une entrée dans la DB RplaceLastBlock
 * Ensemble des informations qui décrivent le dernier block modifié de chaque user
 * Correspond à une ligne de la table RplaceLastBlock en BDD
 * 
 * @see db.ts:DB::RplaceLastBlockTable
 */
export interface RplaceLastBlockEntry {
	id: number
	userId: number
	date: string
}

/**
 * Ensemble des éléménts qui peuvent être possédés
 * @see Asso
 * @see ArticlePublicInformations
 * @see PaymentInfos
 * @see Announce
 * 
 * @see db.ts:DB::OwnableTable
 */
export interface Ownable {
	id: number
	ownerId?: number | null
}

/**
 * Une asso ou un club de Télécom
 * Correspond à une ligne de la table Assos en BDD
 * @see db.ts:DB::AssosTable
 */
export interface Asso extends Ownable {
	img: string | null
	name: string
	description: string
	bureau: string
	link: string | null
}

/**
 * Une asso accompagnée du nom et du prénom de la personne qui gère l'asso
 * @see AssoClub.tsx:AssoCard
 */
export type AssoWithOwner = Asso & FullName;


/**
 * Type d'un article qui peut être séléctionné quand il est créé
 * 
 * @see CreateArticleDialog.tsx:CreateArticleDialog
 */
export enum ArticleType {
	PARTY, // Soirée
	TRIP, // Week-end (WEI, WEE, WES...)
	GOODIE, // Goodie du BDE (Pull de promo...)
	ASSOTICKETING // Article d'asso (Raclette Téléfrom...)
}

export enum AssoArticle {
	BDA,
	BDE_INTE_INTER,
	BDE_MAXI_MARDI,
	BDE_POTS,
	BDE_REPAS_PROMO,
	BDE_VILLAGE_FR,
	BDE_WEE,
	BDE_WEFA,
	BDE_WEI,
	BDE_WES,
	BDI,
	BDS,
	FORUM,
	GALA,
	JE,
	LA_SCENE,
	LUDO,
	OENO,
	TELECOM_BAR,
	TELECOM_POKER,
	TELECOM_RACING,
	TELECOM_VOILE,
	TELEFROM,
	TELEMONTAGNE,
	TELEPLOUF,
	TELESPOIR,
	TELETRAP,
	TGC,
	TSM
}

export interface Price {
	description: string;
	price: number;
}

/**
 * Informations sur un article destinée aux utilisateurs communs
 * @see shop.ts:stripPrivateInformation
 */
export interface ArticlePublicInformations extends Ownable {
	type: ArticleType // Type de l'article (soirée, week-end...)
	asso: AssoArticle
	name: string // Titre de l'article affiché dans la boutique
	description: string // Description de l'article
	img: string | null // URL vers l'affiche de l'article (ex: "https://bde.telecom-paris.fr/uploads/shop/BDIftar-nIGwJ1_G36I.png")
	prices: Price[] | null // Prix pour les différentes catégories de tickets et descriptions
	price_telecom: number // Prix pour les télécommiens non cotisants (role >= TELECOMMIEN)
	price_cotisant: number // Prix pour télécommiens cotisants (cotisant == true)
	price_early_bird: number | null // Prix pour les télécommiens cotisants early bird
	price_extes: number | null // Prix pour les extés (null si pas d'extés)
	price_sam: number | null // Prix pour les sams (null si pas de places SAM)
	extes: boolean // Ouvert aux extés ?
	parrainage: boolean // Système de parrainage pour les extés ?
	early_bird: boolean // Places early bird ?
	sam: boolean // Places SAM ?
	multiple: boolean // Peut-on vendre plusieurs exemplaires de l'article ?
	sale_begin: string // Début de la vente (ex: "2023-04-12T20:00")
	sale_end: string // Fin de la vente (ex: "2023-04-18T20:30")
	sale_end_early_bird: string // Fin de la vente pour les early bird (ex: "2023-04-18T20:30")
	date_begin: string // Début de l'événement (ex: "2023-04-18")
	hour_begin: string // Heure de début (ex: "20:30")
	date_end: string // Fin (ex: "2023-04-18")
	hour_end: string // Heure de fin (ex: "22:30")
	visible: boolean // Si false l'article n'est vu que par les BDE et son propriétaire (ownerID)
	tickets?: Ticket[] // Liste des tickets que l'utilisateur a acheté pour cet événement
	tickets_remaining?: boolean | null
}

export interface ArticleLimits {
	id: number
	telecom_limit: number | null // Nombre max de télécommiens
	extes_limit: number | null // Nombre max d'extés
	global_limit: number | null // Nombre max télécom + extés
}

/**
 * Informations sur l'articles uniquement destinées aux BDE
 * Correspond à une ligne de la table Articles dans la BDD
 * 
 * @member global_limit si les trois limites sont non nulles la limite
 * la plus contraignante est considérée systématiquement
 * 
 * @see db.ts:DB::ArticlesTable
 */
export type Article = ArticlePublicInformations & ArticleLimits;

/**
 * Stats sur la soirées affichées dans ArticleInfosDialog
 * @see ArticleInfosDialog.tsx:ArticleInfosDialog
 */
export type ArticleStats = ArticleLimits & TicketsCount & TicketsRemaining & { income: TicketsIncome };

/* Lydia will return a state as :
 * 
 *  1 - request accepted (transaction completed if the amount is greater than 0€)
 *  0 - Waiting to be accepted
 *  5 - Refused by the payer
 *  6 - Cancelled by the owner
 *  -1 - Unknown (should be considered as an invalid request)
 * 
 * We want them to be compatible with our own states.
 */
export enum TicketStatus {
	CREATED = 0, // L'article a seulement été crée (l'utilisateur a cliqué sur "Acheter")
	PAID = 1, // L'article a été payé
	USED = 2, // L'article a été scanné / utilisé il n'est plus valide
	CANCELAFTERPAY = 3, // L'utilisateur a annulé son achat (sans remboursement) après l'avoir payé
	CANCELBYADMIN = 4, // Un BDE a invalidé le ticket
	CANCELED = 5, // Le paiement a été annulé par l'utilisateur pendant la transaction lydia
	TIMEOUT = 6, // Le paiement a été timeout par lydia
	REFUND = 7, // Le paiement a été remboursé par un admin
	ERROR = -1 // Erreur inconnue
}

// CANCELAFTERPAY est traité à part, car c'est un hybride entre CANCEL (on doit rajouter une place en BDD)
// et USED (la date n'est pas une date de paiement mais d'utilisation).
export type CancelStatus = TicketStatus.CANCELED | TicketStatus.TIMEOUT | TicketStatus.ERROR
	| TicketStatus.CANCELAFTERPAY | TicketStatus.CANCELBYADMIN;

// Ce qui est commun à tout ce que l'on fait payer par Lydia.
export interface PaymentInfos extends Ownable {
	command_date: string | null // Instant où l'utilisateur appuie sur "Acheter" (ex: "2023-03-22T04:27:06.605Z")
	payment_date?: string // Instant où le paiement est confirmé (ex: "2023-03-22T04:27:06.605Z")
	order_ref: string | null // Identifiant unique de la transaction
	paid: boolean // True ssi l'article a bien été payé (ne repasse à false qu'en cas de remboursement)
	price_paid: number
	status: TicketStatus // status du tickets
}

export enum PriceCategory {
	EXTES,
	NONCOTISANT,
	COTISANT,
	EARLY_BIRD,
	SAM,
	CUSTOM
}

/**
 * Ticket correspondant à l'achat d'un article
 * Correspond à une ligne de la table Tickets dans la BDD
 * @see db.ts:DB::TicketsTable
 */
export interface Ticket extends PaymentInfos {
	shopId: number // id de l'article acheté
	used: boolean // le ticket a-t-il été scanné ?
	used_date?: string // date à laquelle le ticket a été scanné (ex: "2023-04-07T20:37:08.976Z")
	phone?: string // Numéro de téléphone avec lequel le ticket a été payé
	email?: string // Email renseigné
	firstname: string, // Prénom de l'acheteur
	lastname: string, // Nom de l'acheteur
	is_telecom: boolean // Est-ce une place télécommien ?
	price_category: PriceCategory
	price_description: string | null
	email_parrain?: string
}

export interface ScanResult {
	ticket: FullTicket
	parrain: User | null
	parrain_used: boolean
}

export type TicketArticle = Ticket & Article;
export interface TicketUser extends Ticket, User {
	blacklistId: number | null // Entrée correspondante à l'acheteur dans la blacklist (null si aucune)
}
export type FullTicket = TicketUser & ArticlePublicInformations;
export interface FullTicketParrain extends FullTicket {
	firstname_parrain?: string
	lastname_parrain?: string
	email_parrain?: string
}
export type FullCotisation = Cotisation & CotisationType & Partial<User>;

export interface TicketsCount {
	shopId: number
	total: number
	telecom: number
	extes: number
	noncotisants: number
	cotisants: number
	early_birds: number
	sam: number
}

export type TicketsIncome = TicketsCount;

export interface TicketsRemaining {
	shopId: number
	global_remaining: number | null
	telecom_remaining: number | null
	extes_remaining: number | null
}

/**
 * Type de cotisation
 */
export interface CotisationType {
	id: number
	name: string // Nom de la cotisation (ex: "Cotisation BDE 3 ans - Premières années")
	price: number // Prix de la cotisation en euros
	duration: number // Durée de la cotisation en mois
	echelon_min: number // Échelon min pour avoir le droit d'acheter la cotisation
	echelon_max: number // Échelon max
	visible: boolean // La cotisation est elle visible par les non-bde ?
}

/**
 * Cotisation d'un utilisateur
 */
export interface Cotisation extends PaymentInfos {
	cotisation_type_id: number, // id vers un CotisationType
}

/**
 * Annonce postée sur le site du BDE
 * Correspond à une ligne de la table Announces dans la BDD
 * @see db.ts:DB::AnnouncesTable
 */
export interface Announce extends Ownable {
	title: string // Titre de l'annonce
	content: string // Contenu
	creation_date: string // Date de création (ex: "2023-03-20T18:05:25.551Z")
	pub_date: string // Date de publication
	public: boolean // L'article est-il visible par les extés ?
	visible: boolean // L'article est-il visible par les non-BDE ?
}

/**
 * Props d'une boite de dialog standard servant à créer un élément
 * @see CreateArticleDialog
 * @see CreateAnnounceDialog
 * @see CreateAssoDialog
 * @see CreateBlacklistEntryDialog
 * @see CreateCotisationDialog
 * @see CreateCotisationTypeDialog
 */
export interface DialogProps<Type> {
	data: Type | null // Données de l'élément à modifier (null s'il faut le créer)
	open: boolean // La boite de dialog est-elle ouvert ?
	onClose: () => void
	onSubmit: (e: Partial<Type>) => void
	onError?: (msg: string) => void
}

export interface AdminDataGridRemoveDialogProps {
	open: boolean,
	rowId: number | string,
	handleRemove: () => void,
	handleCancel: () => void,
	showStatus: (res: Response, msgOK?: string) => Promise<boolean>
}

/**
 * Props d'une "carte" standard servant à afficher un élément
 */
export interface CardProps<Type> {
	editRemoveButtons: ReactNode;
	data: Type // Données à afficher
	visible: boolean // L'élément est-il visible (pour l'afficher en grisé s'il ne l'est pas)
	//editRemoveButtons?: JSX.Element // Boutons d'édition
	children: any // Typiquement le boutons d'achat
}

/**
 * Données d'une éléction
 * Correspond à l'objet stocké dans elections.json
 * 
 * @see Vote
 */
export interface Election {
	electionId: number
	date_begin: string
	date_end: string
	alternatives: {
		troll: boolean,
		name: string,
		short: string,
		img: string,
	} []
}
export interface Pull {
	id: number
	name: string
	image: string
}

/**
 * Vote d'une personne
 * Correspond à un ligne de la table Votes dans la BDD
 * @see db.ts:DB::VotesTable
 */
export interface Vote {
	id: number
	electionId: number
	vote: number
	userId: number
	voted: boolean
}

/**
 * Résultat du vote pour une alternative affiché aux admin
 */
export interface VoteResult {
	vote : number
	count : number
}
export interface VoteInfos {
	id: number
	electionId: number
	voted: boolean
	userId: number
	
}

export interface BuyArticleResponse {
	mobile_url: string,
	order_ref: string
}